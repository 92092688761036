import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

import Layout from "../components/layout"

function ContactPage(props) {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-50px 0px",
  })

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden")
  }, [controls, inView])

  const boxVariants = {
    visible: {
      width: "50%",
      transition: {
        duration: 0.6,
      },
    },
    hidden: {
      width: "100%",
    },
  }

  const formVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      x: -120,
    },
  }

  const textVariants = {
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        // delayChildren: 0.1,
        when: "beforeChildren", // "afterChildren" "beforeChildren"
      },
    },
    hidden: {
      opacity: 0,
    },
  }
  const textChildrenVariants = {
    visible: {
      x: 0,
      opacity: 1,
    },
    hidden: {
      x: -30,
      opacity: 0,
    },
  }

  return (
    <Layout>
      <div className="container relative md:h-324 md:mb-108">
        <div className="pb-32">
          <motion.div
            initial="hidden"
            animate="visible"
            variants={boxVariants}
            className="absolute top-0 bottom-0 left-0 hidden w-1/2 hero-rect h-120 sm:h-160 md:h-240 xl:h-320 bg-gray-50 md:block"
          ></motion.div>

          <motion.div
            ref={ref}
            initial="hidden"
            animate="visible"
            variants={textVariants}
            className="flex md:pt-32"
          >
            <div className="relative w-4/6 hero-content lg:ml-64">
              <motion.p
                variants={textChildrenVariants}
                className="text-black text-20 dash-right"
              >
                Here to help
              </motion.p>
              <motion.h1
                variants={textChildrenVariants}
                className="text-50 lg:text-70 xl:text-150"
              >
                Contact <span className="text-gray-100">us</span>
                <br />
                <p className="text-black text-base font-normal">
                  For an initial discussion about your project contact Ruairi
                </p>
              </motion.h1>
            </div>
          </motion.div>

          <div className="relative z-10 flex flex-col mt-12 lg:ml-64 md:flex-row md:mt-24 xl:mx-64">
            <motion.div
              initial="hidden"
              animate="visible"
              variants={formVariants}
              className="px-12 py-16 md:w-1/2 bg-gray-50 md:bg-transparent md:p-0"
            >
              <form
                method="POST"
                netlify-honeypot="bot-field"
                data-netlify="true"
                className="lg:max-w-lg"
                name="Contact Form"
              >
                <input type="hidden" name="form-name" value="Contact Form" />
                <input type="hidden" name="bot-field" />
                <div className="flex flex-col mb-10">
                  <label htmlFor="name" className="mb-2 text-blue-200">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Your Name"
                    className="px-4 py-2 shadow"
                  />
                </div>

                <div className="flex flex-col mb-10">
                  <label
                    htmlFor="email"
                    className="mb-2 text-blue-200"
                    name="email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Your Email"
                    className="px-4 py-2 shadow"
                  />
                </div>

                <div className="flex flex-col mb-10">
                  <label htmlFor="number" className="mb-2 text-blue-200">
                    Number
                  </label>
                  <input
                    type="text"
                    id="number"
                    name="number"
                    placeholder="Your Number"
                    className="px-4 py-2 shadow"
                  />
                </div>

                <div className="flex flex-col mb-10">
                  <label htmlFor="message" className="mb-2 text-blue-200">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Your Name"
                    className="px-4 py-2 shadow"
                  ></textarea>
                </div>

                <div className="text-right">
                  <button className="btn" type="submit">
                    Send Message
                  </button>
                </div>
              </form>
            </motion.div>
            <div className="flex flex-col md:flex-row">
              <motion.div
                initial="hidden"
                animate="visible"
                variants={textVariants}
                className="flex flex-row mt-24 md:flex-col md:w-1/2 md:pl-20 md:mt-0"
              >
                <div className="flex-grow">
                  <motion.p
                    variants={textChildrenVariants}
                    className="mb-4 text-blue-200"
                  >
                    Address
                  </motion.p>
                  <motion.p variants={textChildrenVariants} className="mb-24">
                    Hawthorne Consulting
                    <br />
                    22A Newry Street
                    <br />
                    Banbridge
                    <br />
                    Co. Down
                    <br />
                    N. Ireland
                    <br />
                    BT32 3HA
                  </motion.p>
                </div>
                <div className="ml-32 md:ml-0">
                  <motion.p
                    variants={textChildrenVariants}
                    className="mb-4 text-blue-200"
                  >
                    Keep in touch
                  </motion.p>
                  <motion.p variants={textChildrenVariants}>
                    +44 2890 775 511
                  </motion.p>
                </div>
              </motion.div>

              <motion.div
                initial="hidden"
                animate="visible"
                variants={textVariants}
                className="flex flex-row mt-24 md:flex-col md:w-1/2 md:pl-20 md:mt-0"
              >
                <div className="flex-grow">
                  <motion.p
                    variants={textChildrenVariants}
                    className="mb-4 text-blue-200"
                  >
                    Address
                  </motion.p>
                  <motion.p variants={textChildrenVariants} className="mb-24">
                    Hawthorne Consulting
                    <br />
                    Loaker Cottage
                    <br />
                    Blackrock Road
                    <br />
                    Blackrock
                    <br />
                    Co. Louth
                    <br />
                    A91 C6X0
                  </motion.p>
                </div>
                <div className="ml-32 md:ml-0">
                  <motion.p
                    variants={textChildrenVariants}
                    className="mb-4 text-blue-200"
                  >
                    Keep in touch
                  </motion.p>
                  <motion.p variants={textChildrenVariants}>
                    +44 7511 533949
                  </motion.p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
